<template>
  <div class='electricAlarmRule'>
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio">
        <div class="title_name">
          <div></div>
          <div>
            <el-button type="text" @click="openDio('add')"><i class="el-icon-circle-plus-outline"></i>添加</el-button>
            <el-button type="text" class="error" @click="batchDel()"><i class="el-icon-delete"></i>批量删除</el-button>
          </div>
        </div>
        <el-table class="tableCont" :data="alarmRuleData" stripe :height="400" style="width:100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" fixed></el-table-column>
          <el-table-column prop="alarmTypeName" label="告警类型" width="100"/>
          <el-table-column prop="alarmCategoryName" label="告警类别" show-overflow-tooltip width="100"/>
          <el-table-column prop="targetName" label="指标" show-overflow-tooltip width="100"/>
          <el-table-column prop="ruleDesc" label="描述" show-overflow-tooltip/>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button type="text" class="primary" @click="openDio('edit',scope.row)"><i class="el-icon-edit"></i>修改</el-button>
              <el-button type="text" class="error" @click="openDio('del',scope.row)"><i class="el-icon-delete"></i>删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <!--创建、修改-->
    <alarm-rule-add-edit ref="editDio" :dicObj="dicObj" @addEditSucc="alarmRuleList()"></alarm-rule-add-edit>
  </div>
</template>
<script>
import alarmRuleAddEdit from './alarm-rule-add-edit'
import { apiUrl } from '@/assets/js/api'
import qs from 'qs'
let vm;
export default {
  props:['dicObj'],
  data() {
    return {
      isDio:false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      inform :{
        id:'',//设备ID
      },
      tableActive:[],
      alarmRuleData:[],//告警规则
    }
  },
  components:{
    alarmRuleAddEdit
  },
  created(){
    vm = this
  },
  methods:{
    //初始化
    init (type,item){
       console.log(item);
      vm.dioType = type
      vm.dioWidth = '840px'
      vm.isDio = true

      vm.$nextTick(() => {
        vm.dioTit = '告警规则'
        vm.inform.id = item.id
        vm.inform.deviceType =  item.deviceType ? item.deviceType : item.devType ? item.devType : 1;
        vm.alarmRuleList()
      })
    },
    //获取告警规则列表
    async alarmRuleList(){
      const res = await apiUrl.alarmRuleList(vm.inform.id)
      if(res?.code==200){
        vm.alarmRuleData = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //打开弹窗
    openDio (type,item){
      if(type == 'del'){
        vm.$confirm('确定删除该选项','提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(() => {
            let arr=[]
            if(item){
              arr.push(item.id)
            }
            vm.deleteAlarmRule(arr)
          })
          .catch(() => {})
      }else{
        if(type == 'add'||type=='edit'){
          vm.$refs.editDio.init(type,item,vm.inform.id,vm.inform.deviceType);//新增或修改
        }
      }
    },
    //批量删除
    batchDel(){
      if(vm.tableActive.length==0){
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      this.$confirm('是否确定要删除所选数据，删除后不可恢复？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let arr = []
        for(var i in vm.tableActive){
          arr.push(vm.tableActive[i].id)
        }
        vm.deleteAlarmRule(arr);//删除
      }).catch(() => {});
    },
    //删除
    async deleteAlarmRule(ids){
      let dataObj = {
        'ids':ids.join(',')
      }
      const res = await apiUrl.deleteAlarmRule(qs.stringify(dataObj))
      if(res?.code==200){
        vm.$message.success('删除成功')
        let totalPage = Math.ceil((this.total - ids.length) / this.limits);
        let current = this.curr > totalPage ? totalPage : this.curr;
        this.curr = current < 1 ? 1 : current;
        vm.alarmRuleList()
      }else{
        vm.$message.error(res.message)
      }
    },
    //表格多选
    handleSelectionChange(val) {
      vm.tableActive = val;
    },
    //关闭弹窗
    closeDio (formEl){
      vm.inform ={
        id:'',
      }
      vm.tableActive = []
      vm.alarmRuleData = [];//告警规则
      vm.isDio = false
    },
  }
}
</script>
<style lang='scss'>
.electricAlarmRule{

}
</style>