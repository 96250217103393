<template>
  <div class="alarmRuleAddEdit">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false"
               @close="closeDio('dioform')">
      <div class="contDio">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="170px" size="small">
          <el-form-item label="告警类别:" prop="alarmCategory">
            <el-select v-model="inform.alarmCategory" placeholder="请选择告警类别">
              <el-option v-for="item in dicObj.alarmData" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"/>
            </el-select>
          </el-form-item>
          <el-form-item label="告警类型:" prop="alarmType">
            <el-select v-model="inform.alarmType" placeholder="请选择告警类型">
              <el-option v-for="item in dicObj.alarmTypeData" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"/>
            </el-select>
          </el-form-item>
          <el-form-item label="连续达到次数消除告警:" prop="maxCancelCount">
            <el-input-number v-model="inform.maxCancelCount" :step="1" :min="0" step-strictly></el-input-number>
          </el-form-item>
          <el-form-item label="连续达到告警最大次数:" prop="maxCount">
            <el-input-number v-model="inform.maxCount" :step="1" :min="0" step-strictly></el-input-number>
          </el-form-item>
          <el-form-item label="告警归并时间长:" prop="mergingTime">
            <el-input-number v-model="inform.mergingTime" :step="1" :min="0" step-strictly></el-input-number>
            秒
          </el-form-item>
          <el-form-item label="指标:" prop="targetId">
            <el-select v-model="inform.targetId" placeholder="请选择指标" @change="targetChange">
              <el-option v-for="item in targetData" :key="item.id" :label="item.name" :value="item.id"/>
            </el-select>
          </el-form-item>
          <template v-if="targetType==0">
            <el-form-item label="状态阈限:" prop="statusThreshold">
              <el-select v-model="inform.statusThreshold" placeholder="请选择状态阈限">
                <el-option v-for="item in dicData" :key="item.statusThreshold" :label="item.dictName"
                           :value="item.statusThreshold"/>
              </el-select>
            </el-form-item>
          </template>
          <template v-if="targetType==1">
            <el-form-item label="值类型:" prop="rule">
              <el-select v-model="inform.rule" placeholder="请选择值类型">
                <el-option v-for="item in dicObj.ruleTypeData" :key="item.dictValue" :label="item.dictName"
                           :value="item.dictValue"/>
              </el-select>
            </el-form-item>
            <el-form-item label="数值阈限:" prop="valueThreshold">
              <el-input-number v-model="inform.valueThreshold" :step="1" :min="0" step-strictly></el-input-number>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {apiUrl} from '@/assets/js/api'
import qs from 'qs'
import {delChildren} from '@/utils/utils'
import {wgs84togcj02, gcj02towgs84} from '@/utils/positionUtil';//百度坐标与gps坐标转换方法
var vm;
export default {
  props: ['dicObj'],
  data() {
    return {
      isDio: false,//弹窗类型
      dioTit: '',//弹窗标题
      dioType: '',//弹窗类型
      dioWidth: '',//弹窗宽度

      targetData: [],//指标列表
      dicData: [],//状态阈限数据
      targetType: -1,//指标类型
      //form信息
      inform: {
        devId: '',//设备id
        id: '',
        alarmCategory: '',//告警类别
        alarmType: '',//	告警类型
        maxCancelCount: 1,//连续达到次数消除告警
        maxCount: 1,//连续达到告警最大次数
        mergingTime: '',//告警归并时间长，单位秒
        rule: '',//值类型
        statusThreshold: '',//状态阈限
        targetId: '',//指标id
        unit: '',//单位
        valueThreshold: '',//数值阈限
      },

      rules: {
        alarmCategory: [
          {required: true, message: '请选择告警类别', trigger: "change"}
        ],
        alarmType: [
          {required: true, message: '请选择告警类型', trigger: "change"}
        ],
        maxCancelCount: [
          {required: true, message: '请输入连续达到次数消除告警', trigger: "blur"}
        ],
        maxCount: [
          {required: true, message: '请输入连续达到告警最大次数', trigger: "blur"}
        ],
        mergingTime: [
          {required: true, message: '请输入告警归并时间长', trigger: "blur"}
        ],
        rule: [
          {required: true, message: '请选择值类型', trigger: 'change'}
        ],
        statusThreshold: [
          {required: true, message: '请选择状态阈限', trigger: 'change'}
        ],
        targetId: [
          {required: true, message: '请选择指标', trigger: 'change'}
        ],
        valueThreshold: [
          {required: true, message: '请输入数值阈限', trigger: "blur"}
        ],
      },
    }
  },
  created() {
    vm = this
  },
  mounted() {

  },
  //方法
  methods: {
    //初始化
    async init(type, item, devId,deviceType) {
       console.log(vm.dicObj);
      vm.dioType = type
      vm.dioWidth = '640px'
      vm.isDio = true
      vm.inform.devId = devId
      await vm.targetList(devId,deviceType)
      vm.$nextTick(() => {
        if (type == 'add') {
          vm.dioTit = '新增告警规则'
        } else if (type == 'edit') {
          vm.dioTit = '编辑告警规则'
          vm.inform.id = item.id;
          vm.inform.alarmCategory = item.alarmCategory.toString();//告警类别
          vm.inform.alarmType = item.alarmType.toString();//	告警类型
          vm.inform.maxCancelCount = item.maxCancelCount;//连续达到次数消除告警
          vm.inform.maxCount = item.maxCount;//连续达到告警最大次数
          vm.inform.mergingTime = item.mergingTime;//告警归并时间长，单位秒
          vm.inform.targetId = item.targetId;//指标id
          vm.inform.unit = item.unit;//单位
          let obj = vm.targetData.find(li => li.id == item.targetId)
          console.log(obj, vm.targetData);
          if (obj) {
            vm.targetType = obj.targetType
            if (obj.targetType == 0) {//状态
              vm.inform.statusThreshold = item.statusThreshold;//状态阈限
              vm.getCurrDics(obj.dictDomain)
            }
            if (obj.targetType == 1) {//数值
              vm.inform.rule = item.rule.toString();//值类型
              vm.inform.valueThreshold = item.valueThreshold;//数值阈限
            }
          }
        }
      })
    },
    //查询指标列表
    async targetList(devId,deviceType) {
      const res = await apiUrl.targetList(devId,deviceType)
      if (res?.code == 200) {
        vm.targetData = res.data
      } else {
        vm.$message.error(res.message)
      }
    },
    //指标下拉选择
    targetChange(val) {
      vm.inform.rule = ''
      vm.inform.statusThreshold = ''
      let obj = vm.targetData.find(item => item.id == val)
      if (obj) {
        vm.inform.unit = obj.unit
        //判断指标类型是0（状态），1（数值）
        vm.targetType = obj.targetType
        if (obj.targetType == 0) {
          vm.getCurrDics(obj.dictDomain)
        }
      }
    },
    //获取数据字典
    async getCurrDics(region) {
      let dataObj = [region];
      vm.dicData = [];
      const res = await apiUrl.getDicts(dataObj)
      if (res?.code == 200) {
        let arr = res.data[region] || [];
        for(var i in arr){
          vm.dicData.push({
            ...arr[i],
            statusThreshold: Number(arr[i].dictValue)
          })
        }

      } else {
        vm.$message.error(res.message)
      }
    },
    //校验form
    checkForm(formEl) {
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if (vm.dioType == 'add') {
            vm.addAlarmRule()
          } else if (vm.dioType == 'edit') {
            vm.updateAlarmRule()
          }
        } else {
          return false
        }
      })
    },
    //关闭弹窗
    closeDio(formEl) {
      // vm.$nextTick(() => {
      vm.$refs[formEl].resetFields()
      // })
      vm.targetData = [];//指标列表
      vm.dicData = [];//状态阈限数据
      vm.targetType = -1;//指标类型
      vm.inform = {
        devId: '',//设备id
        id: '',
        alarmCategory: '',//告警类别
        alarmType: '',//	告警类型
        maxCancelCount: '',//连续达到次数消除告警
        maxCount: '',//连续达到告警最大次数
        mergingTime: '',//告警归并时间长，单位秒
        rule: '',//值类型
        statusThreshold: '',//状态阈限
        targetId: '',//指标id
        unit: '',//单位
        valueThreshold: '',//数值阈限
      }
      vm.isDio = false
    },
    //新增
    async addAlarmRule() {
      let dataObj = {
        'devId': vm.inform.devId,//设备id
        'alarmCategory': vm.inform.alarmCategory,//告警类别
        'alarmType': vm.inform.alarmType,//告警类型
        'maxCancelCount': vm.inform.maxCancelCount,//连续达到次数消除告警
        'maxCount': vm.inform.maxCount,//连续达到告警最大次数
        'mergingTime': vm.inform.mergingTime,//告警归并时间长，单位秒
        'targetId': vm.inform.targetId,//指标id
        'unit': vm.inform.unit,//单位
      }
      if (vm.targetType == 0) {
        dataObj.statusThreshold = vm.inform.statusThreshold;//状态阈限
      } else if (vm.targetType == 1) {
        dataObj.rule = vm.inform.rule;//值类型
        dataObj.valueThreshold = vm.inform.valueThreshold;//数值阈限
      }
      const res = await apiUrl.addAlarmRule(dataObj)
      if (res?.code == 200) {
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      } else {
        vm.$message.error(res.message)
      }
    },
    //修改
    async updateAlarmRule() {
      let dataObj = {
        'id': vm.inform.id,
        'devId': vm.inform.devId,//设备id
        'alarmCategory': vm.inform.alarmCategory,//告警类别
        'alarmType': vm.inform.alarmType,//告警类型
        'maxCancelCount': vm.inform.maxCancelCount,//连续达到次数消除告警
        'maxCount': vm.inform.maxCount,//连续达到告警最大次数
        'mergingTime': vm.inform.mergingTime,//告警归并时间长，单位秒
        'targetId': vm.inform.targetId,//指标id
        'unit': vm.inform.unit,//单位
      }
      if (vm.targetType == 0) {
        dataObj.statusThreshold = vm.inform.statusThreshold;//状态阈限
      } else if (vm.targetType == 1) {
        dataObj.rule = vm.inform.rule;//值类型
        dataObj.valueThreshold = vm.inform.valueThreshold;//数值阈限
      }
      const res = await apiUrl.updateAlarmRule(dataObj)
      if (res?.code == 200) {
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      } else {
        vm.$message.error(res.message)
      }
    }
  }
}
</script>
<style lang='scss'>
.alarmRuleAddEdit {
  .contDio {
    max-height: 500px;
    overflow: auto;
  }
}
</style>